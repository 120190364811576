<template>
  <div class="container">
    <div class="content">
      <List :list="list" />
      <div class="page">
        <el-pagination
          class="pagination"
          background
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="6"
          :total="list.length"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import List from '@/components/List'
import list from '@/utils/data.js'

export default {
  name: 'News',
  components: {
    List
  },
  data() {
    return {
      list
    }
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val, 'val')
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding-top: 73px;
  .content {
    width: 75%;
    margin: auto;
  }
  .page {
    width: fit-content;
    margin: auto;
    margin-top: 22px;
    margin-bottom: 102px;
    .pagination {
      margin: auto;
    }
    ::v-deep(.el-pagination) {
      button {
        border: 2px solid #31ab77;
        width: 92px;
        height: 48px;
        span {
          display: inline-block;
          height: 26px;
          font-size: 20px;
          font-family: MicrosoftYaHei;
          color: #31ab77;
          line-height: 26px;
        }
        &:hover {
          background-color: #31ab77 !important;
          span {
            color: white;
          }
        }
      }
      li {
        width: 48px;
        height: 48px;
        border: 2px solid #31ab77;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #31ab77;
        line-height: 46px;
        &:hover {
          background-color: #31ab77 !important;
          color: white;
        }
      }
      .active {
        background-color: #31ab77 !important;
      }
    }
  }
}
</style>
