<template>
  <div class="container">
    <!-- <el-card shadow="hover"> -->
    <div class="content" v-for="(item, i) in list" :key="i">
      <div class="left">
        <img :src="item.imgurl" alt="" />
        <!-- <img src="@/assets/shouye/dt.png" alt="" /> -->
      </div>
      <div class="right">
        <div class="title">{{ item.title }}</div>
        <div class="des">
          {{ item.des }}
        </div>
        <div class="foot">
          <div class="footleft">发布时间：2023-03-25 16:45</div>
          <div class="footright">
            <el-button type="success" plain @click="onPush(item)"
              >查看详情
              <span class="icon"><i class="el-icon-sort-up"></i></span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  name: 'List',
  props: ['list'],
  methods: {
    onPush(item) {
      this.$store.commit('HANDLEACTIVE', '3')
      this.$router.push(`/news-detail?id=${item.id}`)
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 64px;
  .left {
    img {
      width: 560px;
      height: 315px;
    }
  }
  //   .ellipsis {
  //   display: -webkit-box;
  //   -webkit-line-clamp: 2;
  //   -webkit-box-orient: vertical;
  //   overflow: hidden;
  // }
  // 两行隐藏
  // .overflow2column(@clamp:2) {
  //   overflow: hidden;
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  //   -webkit-line-clamp: @clamp;
  //   overflow: hidden;
  //   white-space: no-wrap;
  //   text-overflow: ellipsis;
  // }
  .right {
    width: calc(100% - 560px);
    padding-left: 36px;
    .title {
      // height: 96px;
      font-size: 32px;
      font-family: MicrosoftYaHei;
      color: #333333;
      line-height: 48px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: no-wrap;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    .des {
      height: 96px;
      font-size: 18px;
      font-family: MicrosoftSansSerif;
      color: #666666;
      line-height: 32px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: no-wrap;
      text-overflow: ellipsis;
    }
    .foot {
      margin-top: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .footleft {
        font-size: 14px;
        font-family: MicrosoftSansSerif;
        color: #999999;
        line-height: 16px;
      }
      .footright {
        button {
          width: 174px;
          height: 51px;
          border: 2px solid #31ab77;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #31ab77;
          line-height: 24px;
          background: white;
          font-weight: bold;
          // position: relative;
          // .btnimg {
          //   margin-left: 20px;
          //   width: 31px;
          //   height: 12px;
          // }
          .icon {
            display: inline-block;
            height: 51px;
            margin-left: 7px;
          }
          .el-icon-sort-up {
            // font-size: 25px;
            font-weight: bolder;
            color: #31ab77;
            transform: scale(1.5) rotateX(180deg) rotateY(0deg) rotateZ(90deg);
          }
        }
        button:hover {
          background: #31ab77;
          color: white;
          .el-icon-sort-up {
            color: white;
          }
        }
      }
    }
  }
}
</style>
